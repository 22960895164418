@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
@import "./font.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Amiri', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@supports (-webkit-touch-callout: none) {
    .h-screen {
        height: -webkit-fill-available !important;
    }

    .min-h-screen {
        min-height: -webkit-fill-available !important;
    }

    .max-h-screen {
        max-height: -webkit-fill-available !important;
    }
}

@layer base {
    video {
        @apply rounded-md max-w-full w-full h-full max-h-full;
    }

    iframe {
        @apply pt-["175%"] max-w-full w-auto h-auto max-h-full;
    }
}

@layer utilities {
    .absolute-center {
        @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }

    .absolute-x-center {
        @apply absolute left-1/2 transform -translate-x-1/2;
    }

    .fixed-center {
        @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }
}
